import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { AuthService } from "../app/auth/auth.service";

export const routes: Routes = [
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthService],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
       // loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
       loadChildren: () => import('./views/users/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'notifications333',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'logout',
        loadChildren: () => import('./views/logout/logout.module').then(m => m.LogoutModule)
      },
      {
        path: 'admin_profile',
        loadChildren: () => import('./views/admin-profile/admin-profile.module').then(m => m.AdminProfileModule)
      },
      {
        path: 'journal-entries',
        loadChildren: () => import('./views/journal/journal.module').then(m => m.JournalModule)
      },
      {
        path: 'journal-entries/:uid',
        loadChildren: () => import('./views/journal/journal.module').then(m => m.JournalModule)
      },
      {
        path: 'analytics',
        loadChildren: ()=> import('./views/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'meditation-session-details/:uid',
        loadChildren: ()=> import('./views/meditation-session-details/meditation-session-details.module').then(m => m.MeditationSessionDetailsModule)
      },
      {
        path: 'app-sessions/:uid',
        loadChildren: ()=> import('./views/app-sessions/app-sessions.module').then(m => m.AppSessionsModule)
      },
      {
        path: 'change-password',
        loadChildren: ()=> import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'favorite-meditation',
        loadChildren: ()=> import('./views/favorite-meditation/favorite-meditation.module').then(m => m.FavoriteMeditationModule)
      }
    ]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    
  },
  // {
  //   path: 'change-password',
  //   component: ChangePasswordComponent,
    
  // },
  {
    path: 'forgot-password/:token',
    component: ResetPasswordComponent,
    
  },
  { path: '**', component: P404Component },
  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
