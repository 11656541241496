import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   icon: 'icon-speedometer',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  {
    name: 'User Management',
    url: 'users',
    icon: 'icon-drop'
  },
  {
    name: 'Notification Management',
    url: 'notifications',
    icon: 'icon-pencil'
  },
  // {
  //   name: 'Journal Entries',
  //   url: 'journal-entries',
  //   icon: 'icon-pencil'
  // },
  // {
  //   name: 'Content Management',
  //   url: '404',
  //   icon: 'icon-puzzle'
  // },
  // {
  //   name: 'Daily News Management',
  //   url: '404',
  //   icon: 'icon-cursor'
  // },
  {
    name: 'Analytics',
    url: '/analytics',
    icon: 'icon-wrench'
  },
  {
    name: 'Change Password',
    url: '/change-password',
    icon: 'icon-lock'
  },
  {
    name: 'Favorite Meditations',
    url: '/favorite-meditation',
    icon: 'icon-star'
  },
  {
    name: 'Log Out',
    url: '/logout',
    icon: 'icon-pie-chart'
  }
  
];
