import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from  "@angular/router";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpHandler  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import {  environment as env  } from "../../environments/environment.prod";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  
  SERVER_URL = env.production == false ? environment.LOCAL_SERVER_URL : environment.SERVER_URL
  constructor(private route:ActivatedRoute,private router:Router,private http:HttpClient,private _snackBar: MatSnackBar) {
    
    // alert(env.production)
    // alert(this.SERVER_URL)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const headers = {
      "Access-Control-Allow-Origin" : "*"
    };
    if(sessionStorage.getItem('token')){      
      headers["Authorization"] = sessionStorage.getItem('token')
    }

    // if(req.url.includes("send/notification")){
    //   headers["Content-Type"] = "multipart/form-data"
    // }

    const authReq = req.clone({ 
      setHeaders: headers
    });
    

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }

  isAuthenticated(){

    if(sessionStorage.getItem('isLogged') && sessionStorage.getItem('isLogged') == "true"){
      return true;
    }else{
      return false
    }

  }
  
  handleError(error) {
    // console.log("czxczxczczxc");
    console.log(error)
    var self = this;
    let errorMessage = '';
    if (error.error) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `${error.message}`;
    }
    // console.log(self._snackBar);
    // self._snackBar.open("Wowwww", "close", {
    //   duration: 2000,
    // });
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  login(data): Observable<any> {
    let API_URL = `${this.SERVER_URL}login`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }
  users(data, email=null): Observable<any> {
    if(email){
      data.email=email
    }
    let API_URL = `${this.SERVER_URL}users`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        }),
        // catchError(this.handleError)
      )
  }

  journalEntries(data): Observable<any> {

    // let API_URL = `${this.SERVER_URL}get/journal/entries`;
    let API_URL = `https://us-central1-mindful-self-discipline.cloudfunctions.net/app/get/journal/entries`
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        }),
        catchError(this.handleError)
      )
  }

  userDetails(data): Observable<any> {

    // let API_URL = `${this.SERVER_URL}get/journal/entries`;
    let API_URL = `https://us-central1-mindful-self-discipline.cloudfunctions.net/app/get/profile`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        }),
        catchError(this.handleError)
      )
  }

  editProfile(data): Observable<any> {
    let API_URL = `${this.SERVER_URL}edit/profile`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        }),
        catchError(this.handleError)
      )
  }

  changeStatus(data): Observable<any> {
    let API_URL = `${this.SERVER_URL}change/status`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  resetPassword(data): Observable<any> {
    var obj = {
      uid: data.uid,
      displayName: data.displayName,
      isManual: data.isManual,
      password:data.password,
      email:data.email
    }
    let API_URL = `${this.SERVER_URL}reset/user/password`;
    console.log(API_URL)
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  updateUserInfo(data): Observable<any> {
    var obj = {
      uid: data.uid,
      displayName: data.displayName,
      email:data.email
    }
    let API_URL = `${this.SERVER_URL}update/user/info`;
    console.log(API_URL)
    return this.http.post(API_URL, obj)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  deleteUser(data): Observable<any> {
    let API_URL = `${this.SERVER_URL}delete/user`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  sendNotification(data): Observable<any> {
    let API_URL = `${this.SERVER_URL}send/notification`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  deleteJournal(data): Observable<any> {
    let API_URL = `${this.SERVER_URL}delete/journal`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  getJournalEntriesPerUser(): Observable<any> {
    let API_URL = `${this.SERVER_URL}getAverageJournalEntries`;
    return this.http.get(API_URL)
               .pipe(
                 map(res => {
                   return res
                 })
               )
  }

  maxJournalEntriesOfUser(): Observable<any> {
    let API_URL = `${this.SERVER_URL}max/journal/entries`;
    return this.http.get(API_URL)
               .pipe(
                 map(res => {
                   return res
                 })
               )
  }

  lifeTrackerData(): Observable<any> {
    let API_URL = `${this.SERVER_URL}getAverageLifeTrackerPositiveAndNegative`;
    return this.http.get(API_URL)
               .pipe(
                 map(res => {
                   return res
                 })
               )

  }

  getAverageRewards(): Observable<any> {
    let API_URL = `${this.SERVER_URL}getAverageRewards`;
    return this.http.get(API_URL)
               .pipe(
                 map(res => {
                   return res
                 })
               )

  }

  getHighestLowestScoreAmongUser(): Observable<any> {
    let API_URL = `${this.SERVER_URL}getHighestLowestScoreAmongUser`;
    return this.http.get(API_URL)
               .pipe(
                 map(res => {
                   return res
                 })
               )

  }

  getMeditationSessionsOfUser(data): Observable<any> {
    let API_URL = `${this.SERVER_URL}getMeditationDataOfUser`;
    return this.http.post(API_URL, data)
                    .pipe(
                      map(res => {
                        return res
                      })
                    )
  }

  getAppUsageSessionsOfUser(data): Observable <any> {
    let API_URL = `${this.SERVER_URL}get/app/usage/sessions/of/user`;
    return this.http.post(API_URL, data)
                    .pipe(
                      map(res => {
                        return res
                      })
                    )
  }    
  
  getAppSessionDataAccordingToDuration(duration):Observable <any> {
    let API_URL = `${this.SERVER_URL}getAllAppUsageSessions/${duration}`;
    return this.http.get(API_URL)
                    .pipe(
                      map(res => {
                        return res
                      })
                    )
  }
  changePassword(data): Observable<any> {
    let API_URL = `https://us-central1-mindful-self-discipline.cloudfunctions.net/app/admin/change/password`;
    console.log(API_URL)
    return this.http.post(API_URL, data).pipe(map(res => {
          return res
        }),
      )
  }
  forgotPassword(data): Observable<any> {
    let API_URL = `https://us-central1-mindful-self-discipline.cloudfunctions.net/app/admin/forgot/password`;
    console.log(API_URL)
    return this.http.post(API_URL, data).pipe(map(res => {
          return res
        }),
      )
  }
  ResetPassword(data): Observable<any> {
    let API_URL = `https://us-central1-mindful-self-discipline.cloudfunctions.net/app/admin/validate/and/reset/password`;
    console.log(API_URL)
    return this.http.post(API_URL, data).pipe(map(res => {
          return res
        }),
      )
  }
  exportCSV(): Observable<any> {
    let API_URL = `https://us-central1-mindful-self-discipline.cloudfunctions.net/app/admin/export/user/csv`;
    return this.http.get(API_URL)
               .pipe(
                 map(res => {
                   return res
                 })
               )
  }

  getFavoriteMeditations(): Observable<any> {
    let API_URL = `${this.SERVER_URL}getFavoriteMeditation`
    return this.http.get(API_URL)
               .pipe(
                 map(res => {
                   return res
                 })
               )
  }


}
