import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AdminService } from "../../service/admin.service";
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  complexForm: FormGroup;
  reqData

  constructor(private route: ActivatedRoute,
  	private router: Router,
    public formBuilder: FormBuilder,
    public AdminService: AdminService,private _snackBar: MatSnackBar) { 
      this.complexForm = this.formBuilder.group({
        'email': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$'), Validators.minLength(1)])],
      })
    }

  ngOnInit(): void {
    this.reqData = {}
  }
  forgotPassword() {
    var obj = {
      email: this.reqData.email,
    }
    console.log(obj)
    this.AdminService.forgotPassword(obj).subscribe(data => {
      console.log(data)
      this._snackBar.open('Reset password link sent to email successfully', "close", {
          duration: 3000,
        });
      
    })
  }

}
