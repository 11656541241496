import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AdminService } from "./../service/admin.service";
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public auth: AdminService, public router: Router) {}
  canActivate(): boolean {
    console.log(this.auth.isAuthenticated())
    if (!this.auth.isAuthenticated()) {
     
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
