import { Component } from '@angular/core';

import { AdminService } from "../../service/admin.service";

import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import {MatSnackBar} from '@angular/material/snack-bar';

import { Router, CanActivate } from '@angular/router';

import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  loginDetails={
    username : "",
    password : "",
  }

  ngOnInit(){    
    if(sessionStorage.getItem("isLogged") == "true"){
        this.router.navigate(["users"])
      }
      this.userIdle.startWatching();
    
      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe(count => console.log(count));
      
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {this.getBackToLogin() }); 
   
      //console.log('Time is up!'));
    
  
   
  }

  isLoading = false;

  form: FormGroup;
  constructor(public router: Router,private adminService:AdminService,private fb: FormBuilder,private _snackBar: MatSnackBar, private userIdle: UserIdleService){
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]]
    })    
  }

  getBackToLogin(){
    sessionStorage.clear();
    this.router.navigate(["login"])
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
  login(){
    console.log("fdgfdgfg",this.form);
    
    // this._snackBar.open("Wowwww", "close", {
    //   duration: 2000,
    // });
    this.isLoading = true
    console.log(this.loginDetails)
    this.adminService.login(this.loginDetails).subscribe(async res => {
      if(res.code == 200){
       
      
        sessionStorage.setItem("admin",JSON.stringify(res.admin));
        sessionStorage.setItem("isLogged","true");
        sessionStorage.setItem("token",res.token);
       
        this.router.navigate(['dashboard']);
        this.restart();
      }
      this.isLoading = false
    },(err) => {
      this.adminService.handleError(err)
      console.log(err)
      this.isLoading = false
    })
    
  }


 }
