import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from "../../service/admin.service";


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  complexForm: FormGroup;
  reqData;
  temp: Boolean = true;
  constructor(private route: ActivatedRoute,
  	private router: Router,
    public formBuilder: FormBuilder,
    public AdminService: AdminService,private _snackBar: MatSnackBar)
     { 
       this.complexForm = this.formBuilder.group({
        'new_password':  [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}')])],
        'confirm_password': ['']
      },{validators: this.checkPasswords})
    }

  ngOnInit(): void {
    // let token = this.route.snapshot.params["token"];
  	// console.log("token",token)
    this.reqData = {}

  }
  checkPasswords(group: FormGroup) { 
	  let pass = group.get('new_password').value;
	  let confirmPass = group.get('confirm_password').value;

	  return pass === confirmPass ? null : { notSame: true }     
	}

  get rpf() {
      return this.complexForm.controls;
  }

  resetPassword(){
    let token = this.route.snapshot.params["token"];
  	console.log("token",token)
    console.log("dfsafdsfdsfdsfdsfds",this.complexForm);
    console.log("kfjfgkjflgjgfhjgklfh",this.reqData);
    

  	var obj = {
      password: this.reqData.new_password,
      token: token    
    }
    console.log("object is", obj)
    this.AdminService.ResetPassword(obj).subscribe(data => {
      console.log(data)
      this._snackBar.open('Password Reset Successfully!!', "close", {
        duration: 3000,
      });
     
    })

  }


}
